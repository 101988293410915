/* eslint-disable no-unused-expressions */
import {
    Box,
    Container,
    Grid,
    Skeleton,
    Stack,
    styled,
    SwipeableDrawer,
    TextField,
    Typography,
    IconButton,
    Button,
    CircularProgress,
    Divider,
    CardContent,
    Card,
    Snackbar,
    List,
    ListItem,
} from "@mui/material";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { db } from "../../firebase.config";
import CustomButton from "../../Components/CustomButton";
import EventCard from "../../Components/EventCard";
import Iconify from "../../Components/Iconify";
import { sendPasswordResetEmail } from "firebase/auth";
import { Facebook, Google } from "@mui/icons-material";
import { Variables } from "../../context/VariableContext";
import CustomTextField from "../../Components/CustomTextField";
import Info from "./info";
import Monitoring from "./monitoring";
import { useNavigate, useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useResponsive from "../../Components/hooks/useResponsive";
import { QrReader } from "react-qr-reader";
import { Html5QrcodeScanner } from "html5-qrcode";
import Scanner from "../scanner/scanner";
import { Sheet } from "react-modal-sheet";
import MenuPopover from "../../Components/MenuPopover";
import { useSnackbar } from "../../context/snackbarContext";

const Affiliation = ({ open, count }) => {
    const { eventId } = useParams();
    const [size, setSize] = useState(280);
    const [more, setMore] = useState(false);
    const [scanResult, setScanResult] = useState(null);
    const [order, setOrder] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [pause, setPause] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [scanValidate, setScanValidate] = useState(null);
    const [isOpen, setOpen] = useState(false);

    const [search, setSearch] = useState('');
    const [results, setResults] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const showSnackbar = useSnackbar()


    //  snack bar
    const [openSnack, setOpenSnack] = React.useState(false);
    const [msg, setMsg] = React.useState({ content: '', color: '' });
    const handleCloseSnack = () => {
        setOpenSnack(false);
    };
    const showSnack = (m) => {
        setMsg(m)
        setOpenSnack(true);
    };


    const handleScanSuccess = (result, isValid) => {
        setPause(true)
        if (result?.isValid) {
            // update du billet 
            setScanResult(result);
            // console.log(result)
            setOpen(true)
        } else {
            if (result?.returnCode === 'ALREADY_SCAN') {
                showSnackbar("billet déja scanné", false)
            } else if (result?.returnCode === 'NOT_VALID') {
                showSnackbar("Billet non valide", true)
            } else if (result?.returnCode === 'NOT_FOUND') {
                showSnackbar("Ce billet n'existe pas", true)
            }

            setPause(false)
        }

    };

    const showMore = async (refCommande) => {
        setLoading(true)
        setMore(true)
        try {
            setSize(460)
            setOrder(null)
            const docRefOrder = doc(db, `evenement/${eventId}/commandes`, `${refCommande}`);
            const docSnapOrder = await getDoc(docRefOrder);
            console.log(docSnapOrder.data())
            setOrder(docSnapOrder.data())
            setLoading(false)
        } catch (error) {
            setOpen(false)
            setPause(false)
            console.error(error)
            setLoading(false)
        }
    };

    const validateScan = async (ticketNumber) => {
        setLoading(true)
        let done = false
        try {
            const docRefTicket = doc(db, `evenement/${eventId}/billets`, `${ticketNumber}`);
            await updateDoc(docRefTicket, {
                scanner: 1
            });
            done = true
            showSnackbar("Billet validé", false)
            setOpen(false)
            setPause(false)
        } catch (error) {
            setPause(false)
            console.error(error)
            showSnackbar("Une erreur es survenue dans la validation du billet", true)
            setLoading(false)
        }
        setLoading(false)
        setMore(false)
        return done

    };

    useEffect(() => {
        if (search.length >= 3) {
            fetchResults();
        } else {
            setResults([]);
        }
    }, [search]);

    const fetchResults = async () => {
        setLoading(true);
        try {
            const ticketsRef = collection(db, `evenement/${eventId}/billets`);

            // Recherche dans les champs ticketNumber, orderId et buyerName
            const q = query(
                ticketsRef,
                where("numero", ">=", search),
                where("numero", "<", search + "\uf8ff")
            );

            const querySnapshot = await getDocs(q);
            const tickets = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setResults(tickets);
        } catch (error) {
            console.error("Erreur lors de la recherche :", error);
        }
        setLoading(false);
    };

    const selectItem = async (ticket) => {
        setLoading(true)
        let done = false
        try {
            if (ticket.scanner === 0 && ticket.status === 1) {
                setScanResult({ ticketNumber: ticket.id, ticketData: ticket, isValid: true, returnCode: 'READY_TO_SCAN' });
            } else if (ticket.scanner === 1) {
                setScanResult({ ticketNumber: ticket.id, ticketData: ticket, isValid: false, returnCode: 'ALREADY_SCAN' });
            }

            setOpen(true)

        } catch (error) {
            setPause(false)
            console.error(error)
            setLoading(false)
        }
        setLoading(false)
        setMore(false)
        return done

    };
    const navigate = useNavigate();
    React.useEffect(() => {
        (async () => {
            try {
                const userData = JSON.parse(sessionStorage.getItem('userData'))
                const currentEvent = sessionStorage.getItem('currentEvent')
                const data = await getDoc(doc(db, `evenement/${currentEvent}/operateurs`, `${userData.email}`));
                console.log(data.data())
                if (data.data().isActive === false) {
                    navigate('/', { replace: true })
                    showSnackbar("l'organisateur a desactivé votre lien de verification", true)
                }
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        })();
    }, []);

    return (
        <Container sx={{ color: 'white' }}>
            <Box paddingBottom={3}>
                <CustomButton onClick={() => navigate(-1, {replace: true})}  startIcon={<Iconify icon="material-symbols:arrow-back-ios" />} type={'secondaire'} buttonText={'retour'}/>
            </Box>
            
            <CustomTextField value={search}
                onChange={(e) => setSearch(e.target.value)} fullWidth placeholder='Rechercher un billet' />
            {isSearch && <CircularProgress size={24} />}
            <List>
                {results.map((ticket) => (
                    <ListItem onClick={() => selectItem(ticket)} key={ticket.id}> {ticket.nom} - {ticket.numero} ({ticket.refCommande})</ListItem>
                ))}
            </List>

            <Stack>
            </Stack>
            <Scanner onScanSuccess={handleScanSuccess} eventId={eventId} refresh={refresh} pause={pause} />
            <Sheet isOpen={isOpen} onClose={() => {
                setPause(false),
                    setOpen(false),
                    setSize(280),
                    setMore(false)
            }}
                snapPoints={[size]}>
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                        <Stack padding={2} spacing={2}>
                            {
                                more ?
                                    <Stack spacing={1} >
                                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                            <Iconify icon='pepicons-pencil:ticket' sx={{ height: 30, width: 30 }} />
                                            <Typography variant="subtitle1">Information sur le ticket</Typography>

                                        </Stack>
                                        <Stack padding={2} sx={{ bgcolor: '#f2f2f2' }}>
                                            <Stack direction={'row'}>
                                                <Typography variant="body2">Ticket N° {scanResult?.ticketNumber}</Typography>
                                            </Stack>

                                            <Stack direction={'row'} justifyContent={'space-between'}>
                                                <Typography variant="body2">Nom du ticket</Typography>
                                                <Typography variant="body2">{scanResult?.ticketData.nom}</Typography>
                                            </Stack>
                                            <Stack direction={'row'} justifyContent={'space-between'}>
                                                <Typography variant="body2">Prix</Typography>
                                                <Typography variant="body2">{scanResult?.ticketData.price} CFA</Typography>
                                            </Stack>

                                        </Stack>
                                        <Stack paddingTop={2} direction={'row'} alignItems={'center'} spacing={1}>
                                            <Iconify icon='lets-icons:order-light' sx={{ height: 30, width: 30 }} />
                                            <Typography variant="subtitle1">Information sur la commande</Typography>
                                        </Stack>
                                        <Stack padding={2} sx={{ bgcolor: '#f2f2f2' }}>
                                            <Stack direction={'row'}>
                                                <Typography variant="body2">Commande N° {scanResult?.ticketData.refCommande}</Typography>
                                            </Stack>

                                            <Stack direction={'row'} justifyContent={'space-between'}>
                                                <Typography variant="body2">Nom acheteur</Typography>
                                                <Typography variant="body2">{order?.nom}</Typography>
                                            </Stack>
                                            <Stack direction={'row'} justifyContent={'space-between'}>
                                                <Typography variant="body2">Email acheteur</Typography>
                                                <Typography variant="body2">{order?.email}</Typography>
                                            </Stack>
                                            <Stack direction={'row'} justifyContent={'space-between'}>
                                                <Typography variant="body2">Nombre de ticket</Typography>
                                                <Typography variant="body2">{order?.billets?.length}</Typography>
                                            </Stack>
                                            <Stack direction={'row'} justifyContent={'space-between'}>
                                                <Typography variant="body2">Montant total</Typography>
                                                <Typography variant="body2">{order?.totalPrice} CFA</Typography>
                                            </Stack>

                                        </Stack>

                                    </Stack> :
                                    <Stack spacing={1} justifyContent={'center'} alignItems={'center'}>
                                        <Iconify icon='pepicons-pencil:ticket' sx={{ height: 40, width: 40 }} />
                                        <Typography variant="subtitle1"><strong>{scanResult?.ticketData.nom}</strong></Typography>
                                        <Typography variant="body2">N° {scanResult?.ticketNumber}</Typography>
                                        <CustomButton buttonText={"afficher plus d'info"} type={'tertiaire'} onClick={() => showMore(scanResult?.ticketData.refCommande)} />
                                    </Stack>
                            }

                            {
                                isLoading ? <CircularProgress /> : scanResult?.ticketData.scanner === 0 && scanResult?.ticketData.status === 1 ? <CustomButton buttonText={'Valider'} onClick={() => validateScan(scanResult?.ticketNumber)} /> : null
                            }

                        </Stack>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop />
            </Sheet>
        </Container>
    );
};

export default Affiliation;
