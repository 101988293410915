// Scanner.js
import React, { useEffect, useState } from 'react';
import { Html5QrcodeScanner, Html5QrcodeScanType } from 'html5-qrcode';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase.config';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../context/snackbarContext';

const Scanner = ({ onScanSuccess, eventId, refresh, pause }) => {
    const [scanner, setScanner] = useState(null)
    const navigate = useNavigate();
    const showSnackbar = useSnackbar()
    useEffect(() => {
        const scanner = new Html5QrcodeScanner('reader', {
            qrbox: {
                width: 250,
                height: 250,
            },
            fps: 2,
            supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA]
        });

        // Render le scanner avec une fonction pour les résultats
        scanner.render(success, error);
        setScanner(scanner)

        // Fonction appelée lorsque le QR code est scanné
        async function success(result) {
            try {
                console.log('start searching')
                scanner.pause()
                const userData = JSON.parse(sessionStorage.getItem('userData'))
                const currentEvent = sessionStorage.getItem('currentEvent')
                const data = await getDoc(doc(db, `evenement/${currentEvent}/operateurs`, `${userData.email}`));
                console.log(data.data())
                if (data.data().isActive === false) {
                    navigate('/', { replace: true })
                    showSnackbar("l'organisateur a desactivé votre lien de verification", true)
                    return
                }
                const docRefTicket = doc(db, `evenement/${eventId}/billets`, `${result}`);
                const docSnapTicket = await getDoc(docRefTicket);
                if (docSnapTicket.exists()) {
                    if (docSnapTicket.data().scanner === 0 && docSnapTicket.data().status === 1) {
                        onScanSuccess({ ticketNumber: result, ticketData: docSnapTicket.data(), isValid: true, returnCode: 'READY_TO_SCAN' });
                    } else if (docSnapTicket.data().scanner === 1) {
                        onScanSuccess({ ticketNumber: result, ticketData: docSnapTicket.data(), isValid: false, returnCode: 'ALREADY_SCAN' });
                    } else {
                        onScanSuccess({ ticketNumber: result, isValid: false, returnCode: 'NOT_VALID' });
                    }
                    // open bottomsheet to s
                }
                else {
                    onScanSuccess({ ticketNumber: result, isValid: false, returnCode: 'NOT_FOUND' });
                }
                console.log('finish searching')
            } catch (error) {
                console.log(error);
            }
            scanner.resume()

        }

        function error(err) {
            // console.warn("Scan erreur:", err);
            // Vous pouvez ajouter des actions ici en cas d'erreurs fréquentes
        }

        // Nettoyer le scanner lorsque le composant est démonté

    }, [eventId]);

    useEffect(() => {

        if (pause) {
            scanner?.pause()
        } else {
            scanner?.resume()
        }

    }, [eventId, pause]);

    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <div id="reader" style={{ width: "100%" }}></div>
        </div>
    );
};

export default Scanner;
