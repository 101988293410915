import React, { useEffect, useState } from 'react'

import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import { Box, CircularProgress } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase.config';

const ProtectedRoute = ({ children }) => {
    const { user, logOut } = UserAuth();
    const [checkPartners, setCheckPartners] = useState(false);
    const navigate = useNavigate();
    const { eventId } = useParams();
    useEffect(() => {
        (async () => {
            if (user != null) {
                try {
                    const u1 = await getDoc(doc(db, "organisateurs", user.uid));
                    console.log(user.uid)
                    console.log(user)
                    sessionStorage.setItem("userData", JSON.stringify({id: user.uid, email: user.email}));
                    if (u1?.exists()) {
                        if (eventId) {
                            const eventRef = doc(db, 'evenement', eventId);
                            const eventSnap = await getDoc(eventRef);
                            if (eventSnap.exists()) {
                                if (eventSnap.data()?.orgId === u1.data()?.idOrg) {
                                    setCheckPartners(true);
                                } else {
                                    navigate('/home');
                                }
                            } else {
                                navigate('/home');
                            }
                        } else {
                            setCheckPartners(true);
                        }

                    } else if (user) {
                        const operateur = await getDoc(doc(db, "operateurs", user.uid));
                        if (operateur.exists()) {
                            if (eventId) {
                                const list = operateur.data().events
                                const eventExists = list.some(event => event === eventId);
                                if (eventExists) {
                                    setCheckPartners(true);
                                } else {
                                    logOut();
                                    navigate('/login');
                                }
                            } else {
                                setCheckPartners(true);
                            }
                        }
                    }
                    else {
                        logOut();
                        console.log('user non trouvé')
                        navigate('/login');
                    }

                } catch (error) {
                    console.log(error);
                }
            } else {
                navigate('/login')
            }
        })();
    }, [user, eventId]);

    if (!checkPartners) {
        // return <Navigate to='/login'/>
        return (
            <Box height={'100%'} width={'100%'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }
    return children

}

export default ProtectedRoute