import {
    Box,
    Container,
    Grid,
    Skeleton,
    Stack,
    styled,
    SwipeableDrawer,
    TextField,
    Typography,
    IconButton,
    Button,
    CircularProgress,
    Divider,
} from "@mui/material";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import React, { useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { auth, db, functions } from "../firebase.config";
import CustomButton from "../Components/CustomButton";
import EventCard from "../Components/EventCard";
import Iconify from "../Components/Iconify";
import { sendPasswordResetEmail, signInWithCustomToken } from "firebase/auth";
import { Facebook, Google } from "@mui/icons-material";
import { Variables } from "../context/VariableContext";
import CustomTextField from "../Components/CustomTextField";
import { useNavigate } from "react-router-dom";
import logoImg from "../media/mainLogo Partners.png";
import { httpsCallable } from "firebase/functions";

const Authenticate = () => {
    const { user, logIn, signUpSecondStep, logOut, forgotPass, handleFacebookSigin, faceSecondStep } = UserAuth();
    const { openLogin, setOpenLogin } = Variables();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);



    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        const verifyToken = async () => {
            console.log(token);
            if (!token) {
                alert("Lien invalide");
                return;
            }
            const linkRef = doc(db, "authLinks", token);
            const linkSnap = await getDoc(linkRef);
            if (!linkSnap.exists()) {
                alert("Lien non valide");
                return;
            }
            
            console.log(linkSnap.data())
            const { email, uid, name, eventID, expirationTime, isConnected, isActive } = linkSnap.data();
            if (!isActive) {
                alert("Ce lien est inactif");
                return;
            }
            
            /* if (isConnected) {
                alert("Lien expiré ou déjà utilisé");
                return;
            } */
            // Marquer le lien comme utilisé
            // await updateDoc(linkRef, { isUsed: true });
            try {
                // Simuler l'authentification avec un token Firebase
                const generateCustomToken = httpsCallable(functions, "generateCustomToken");
                const response = await generateCustomToken({
                    uid
                });
                console.log('link');
                console.log(sessionStorage);
                const customToken = response.data.customToken
                if (customToken != null) {
                    signInWithCustomToken(auth, customToken)
                        .then(async () => {
                            const listEvent = JSON.parse(sessionStorage.getItem("listEvent")) || [];
                            const eventExists = listEvent.some(event => event.id === eventID);
                            if (!eventExists) {
                                listEvent.push({ id: eventID })
                            }
                            console.log(listEvent);
                            sessionStorage.setItem("listEvent", JSON.stringify(listEvent)); // Limite l'accès à cet événement
                            await updateDoc(linkRef, { isConnected: true });
                            navigate("/home", { replace: true }); // Rediriger vers la page de scan
                        })
                        .catch((error) => {
                            console.error("Erreur d'authentification:", error);
                        });
                }

            } catch (error) {
                console.error(error)
            }

        };

        verifyToken();
    }, [db, user, navigate]);



    return (
        <Container>
            <Stack justifyContent='center' alignItems='center' spacing={1} sx={{ color: 'white' }}>
                <CircularProgress sx={{ width: 50, height: 50 }} />
            </Stack>

        </Container>
    );
};

export default Authenticate;
