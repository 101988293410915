import {
  Box,
  Container,
  Stack,
  Typography,
  Card,
} from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React from "react";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase.config";
import CustomTextField from "../Components/CustomTextField";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

const Home = ({ open, count }) => {
  const { user } = UserAuth();
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false);
  const [events, setEvents] = React.useState([]);



  const getData = async () => {
    try {
      setLoading(true);
      setEvents([]);

      if (user) {
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);

        if (docSnap.exists()) {
          const eventsList = [];
          const orgId = docSnap.data().idOrg;
          if (!orgId) {
            throw new Error("orgId not found");
          }
          let q;
          q = query(
            collection(db, 'evenement'),
            where('orgId', '==', orgId),
            where('status', '==', 1),
            orderBy('dateDebut', 'desc')
          );
          const querySnapshot = await getDocs(q);

          // const recetteList = [];
          querySnapshot.forEach(async (snap) => {
            const event = snap;
            eventsList.push(event);
          });
          const operateur = await getDoc(doc(db, "operateurs", user.uid));
          if (operateur.exists()) {
            const eventsList = [];
            const list = operateur.data().events
            for (let index = 0; index < list.length; index++) {
              const element = list[index];
              const docRefEvent = doc(db, 'evenement', element);
              const docEvent = await getDoc(docRefEvent);
              if (docEvent.exists()) {
                const event = docEvent;
                eventsList.push(event);
              }
            }
            setEvents(eventsList);
          }
          setEvents(eventsList);
        } else {
          const operateur = await getDoc(doc(db, "operateurs", user.uid));
          if (operateur.exists()) {
            const eventsList = [];
            const list = operateur.data().events
            for (let index = 0; index < list.length; index++) {
              const element = list[index];
              const docRefEvent = doc(db, 'evenement', element);
              const docEvent = await getDoc(docRefEvent);
              if (docEvent.exists()) {
                const event = docEvent;
                eventsList.push(event);
              }
            }
            setEvents(eventsList);
          }

        }

      }
    } catch (error) {
      console.log("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  const initialState = async () => {
    try {
      setLoading(true);
      setEvents([]);

      if (user) {
        const userData = JSON.parse(sessionStorage.getItem('userData'))
        const currentEvent = sessionStorage.getItem('currentEvent')
        console.log(userData)
        console.log(currentEvent)
        if (currentEvent) {
          await updateDoc(doc(db, `evenement/${currentEvent}/operateurs`, `${userData.email}`), { isScanning: false });
          sessionStorage.removeItem('currentEvent')
        }
      }
    } catch (error) {
      console.log("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };


  React.useEffect(() => {
    (async () => {
      try {
        // check if current event, then stop scanning and delete current event
        initialState()
        // get event data
        getData()

      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);



  const goToEvent = async (index) => {
    // activer le scanne
    const userData = JSON.parse(sessionStorage.getItem('userData'))
    const currentEvent = sessionStorage.getItem('currentEvent')
    console.log(userData)
    console.log(currentEvent)
    await updateDoc(doc(db, `evenement/${events[index].id}/operateurs`, `${userData.email}`), { isScanning: true });
    sessionStorage.setItem("currentEvent", events[index].id);
    navigate(`/scanner/${events[index].id}`)
  };


  return (
    <Container>
      <Stack sx={{ color: 'white' }} spacing={3}>
        <Typography variant="h5">Mes evenements</Typography>

        <Stack spacing={2} justifyContent='center' paddingTop={2}>
          {
            events.length === 0 && loading && <>
              <Typography variant="body2">Vous n'êtes affilié a aucun évenement pour le moment</Typography>
              <Typography variant="body2">Veuillez contacter des organisateurs pour mettre en place des partenariat</Typography>
            </>
          }

          <CustomTextField placeholder='Rechercher un evenement' />
          {events.map((event, index) => (
            <Card sx={{ bgcolor: '#292929', color: 'white', cursor: 'pointer', borderRadius: '10px' }} onClick={() => goToEvent(index)}>
              <Stack direction='row' spacing={1} display="flex" alignItems="center" padding={0.5}>

                <LazyLoadImage
                  src={`${event.data().idVisuel}`}
                  alt={`${event.data().idVisuel}`}
                  className="img-lazy"
                  width={100}
                  height={100}
                  style={{ objectFit: 'cover', borderRadius: '10px' }}
                  effect="blur" // opacity | black-and-white
                />
                <Stack spacing={0.5}>
                  <Typography variant="Body2"><strong>{event?.data().nomEvent}</strong></Typography>
                  <Typography variant="body" >
                    dans 3 jours
                  </Typography>
                  <Typography variant="body1" >
                    {event?.data().participant} participants
                  </Typography>
                </Stack>
              </Stack>
            </Card>))}
          <Stack>

          </Stack>
        </Stack>
      </Stack>

    </Container>
  );
};

export default Home;
