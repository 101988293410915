/* eslint-disable no-nested-ternary */
import React, { createContext, useState, useContext, useEffect } from "react";
import { Snackbar, Alert, Stack, Typography, Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import Iconify from "../Components/Iconify";

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    isError: false, // Options: "error", "warning", "info", "success"
  });

  const showSnackbar = (msg, isError = null) => {
    setSnackbar({ open: true, msg, isError });
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const [searchParams] = useSearchParams();
          const [accessToken, setAccessToken] = useState();
      useEffect(() => {
              // Vérifie si un token est déjà stocké
              console.log('////////////////')
              
              const token = Cookies.get("facebook_access_token")
              console.log(token)
              if (token) {
                setAccessToken(token);
              } else {
                const token = searchParams.get("fb_access_token");
                console.log(token)
                if (token) {
                  Cookies.set(`facebook_access_token`, JSON.stringify(token), { expires: 7 });
                  setAccessToken(token);
                }
              }
            }, [searchParams]);
  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Snackbar open={snackbar.open}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Stack spacing={1} direction={'row'} sx={{ padding: 2, borderRadius: '7px', bgcolor: '#daccff', alignItems: 'center'}}>
          {
            snackbar.isError === null ? 
            <Iconify icon={'material-symbols:error-outline-rounded'} sx={{minWidth:25, minHeight:25}}/>
            : snackbar.isError === true ?
            <Iconify icon={'material-symbols:error-outline'} sx={{color:'red', minWidth:25, minHeight:25}}/>
            : <Iconify icon={'qlementine-icons:success-16'} sx={{color:'green',minWidth:25, minHeight:25}}/>
          }
          
          <Typography paddingLeft={1} variant="body2">{snackbar.msg}</Typography>
        </Stack>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);