import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
    Box,
    Divider,
    Typography,
    Stack,
    MenuItem,
    Avatar,
    IconButton,
    ListItemIcon,
    ListItemText,
    Link,
} from '@mui/material';
// components
import { doc, getDoc } from 'firebase/firestore';

// mocks_

import Iconify from './Iconify';
import { UserAuth } from '../context/AuthContext';
import useResponsive from './hooks/useResponsive';
import { db } from '../firebase.config';
import MenuPopover from './MenuPopover';
import { useSnackbar } from '../context/snackbarContext';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [

    {
        label: "Centre d'aide",
        linkTo: 'https://support.choptonticket.com/fr',
    }
];

const MENU_OPTIONS_MOBILE = [
    
    {
        label: "Centre d'aide",
        linkTo: 'https://support.choptonticket.com/fr',
    },
];

// ----------------------------------------------------------------------

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: '#5E17EB',
        },
        children: `${name.split(' ')[0][0]}`,
    };
}

export default function AccountPopover() {
    const { user, logOut } = UserAuth();
    const anchorRef = useRef(null);
    const [username, setUsername] = useState('');
    const [userInfo, setUser] = useState();
    const [org, setOrg] = useState();
    const [logo, setLogo] = useState();
    const showSnackbar = useSnackbar()

    const navigate = useNavigate();

    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef0 = doc(db, 'partners', `${user.uid}`);
                console.log(user);
                // setLoading(true);
                const docSnap = await getDoc(docRef0);
                if (docSnap.exists()) {
                    setUsername(`${docSnap.data().name}`.toUpperCase());
                    setUser(docSnap.data());
                    console.log(docSnap.data());
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData().catch(console.error);
    }, [user]);

    // log out function
    const handleLogout = async () => {
        try {
            await logOut();
            navigate('/login', { replace: true });
            showSnackbar("Deconnecté avec succès", false)
        } catch (error) {
            console.log(error);
        }
        setOpen(null);
    };
    const smUp = useResponsive('up', 'sm');
    const mdUp = useResponsive('up', 'md');

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    p: 0,
                    borderRadius: '30px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '30px',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[400], 0.8),
                        },
                    }),
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: '#292929', borderRadius: '30px', padding: 1 }}>
                    <Iconify icon={'ant-design:menu-outlined'} sx={{color: 'white'}}/>
                </Box>
            </IconButton>
            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    width: 250,
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >

                {!mdUp ? (
                    <Stack sx={{ p: 1 }}>
                        <Box padding={1}>
                            <Typography noWrap variant="body2">
                                <strong>{user && user.email}</strong>
                            </Typography>
                        </Box>
                        <Divider sx={{ borderStyle: 'dashed' }} />
                        {MENU_OPTIONS_MOBILE.map((option) => (
                            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                                <ListItemText>{option.label}</ListItemText>
                            </MenuItem>
                        ))}
                    </Stack>
                ) : <Stack sx={{ p: 1 }}>
                    <Box padding={1}>
                        <Typography noWrap variant="body2">
                            <strong>{user && user.email}</strong>
                        </Typography>
                    </Box>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                            <ListItemText sx={{ fontSize: 14 }}><Typography variant='body2'>{option.label}</Typography></ListItemText>
                        </MenuItem>
                    ))}
                </Stack>}

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem onClick={handleLogout} sx={{ m: 1, color: 'red' }}>
                    <ListItemText><Typography variant='body2'>Deconnexion</Typography></ListItemText>
                </MenuItem>
            </MenuPopover>
        </>
    );
}
